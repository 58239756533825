import {Component, Input, OnInit} from '@angular/core';

import {ItemsService} from './items.service';

@Component({
  selector: 'app-liabilities-supplier-expansion',
  templateUrl: './item-expansion.component.html'
})
export class ItemExpansionComponent implements OnInit {

  @Input() url: string;
  @Input() supplierId: string;
  @Input() rowData: any;

  items: Array<any>;

  constructor(
    private itemsService: ItemsService,
  ) {
  }

  ngOnInit(): void {
    this.itemsService.getItems(this.url, this.supplierId, this.rowData.invoiceId).subscribe(({listData}) => {
      this.items = listData;
    });
  }

}
