import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {LazyLoadEvent} from 'primeng/api';

import {ItemsService} from './items.service';
import {ItemsService as SuppliersService} from '../../purchases/suppliers';

@Component({
  selector: 'app-liabilities-suppliers',
  templateUrl: './items.component.html'
})
export class ItemsComponent implements OnInit {

  url: string;

  suppliers: Array<any>;
  supplierId: string;

  event: LazyLoadEvent;
  items: Array<any>;
  totalRecords: number;

  constructor(
    private router: Router,
    private itemsService: ItemsService,
    private suppliersService: SuppliersService
  ) {
  }

  ngOnInit() {
    if (this.router.url === '/Financial/liabilities-suppliers') {
      this.url = 'dchn-accounting/liabilities/suppliers';
    } else {
      this.url = 'fsn-accounting/liabilities/suppliers';
    }
    this.suppliersService.getList('').subscribe(({listData}) => {
      this.suppliers = new Array<any>();
      listData.forEach((item) => {
        this.suppliers.push({value: item.partyId, label: `${item.pseudoId} - ${item.partyName}`});
      });
    });
  }

  reLoadLazy() {
    this.loadLazy(Object.assign({first: 0, rows: 10}, this.event));
  }

  loadLazy(event: LazyLoadEvent) {
    this.event = event;
    if (this.url && this.supplierId) {
      this.itemsService.getList(this.url, this.supplierId, JSON.stringify(event)).subscribe(res => {
        this.items = res.listData;
        this.totalRecords = res.totalRecords;
      });
    }
  }

}
